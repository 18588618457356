<template>
  <div>
    <DiagnosticImageEdit
          v-if="isEditDiagnosticImageSidebarActive"
          :is-edit-diagnostic-image-sidebar-active.sync="
            isEditDiagnosticImageSidebarActive
          "
          :itemEdit="itemEdit"
          :optionsCategories="optionsCategories"
          @editDiagnosticImageSuccess="editDiagnosticImageSuccess"
        />
    <DiagnosticImageCategoryFilter
      v-if="$can('filter', 'diagnostic-image-categorys')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable
        titleTable="Diagnostic Image Categories"
        iconTable="fas fa-disease"
      />
      <div class="m-1">
        <DiagnosticImageCategoryEdit
          v-if="isEditDiagnosticImageCategorySidebarActive"
          :is-edit-diagnostic-image-category-sidebar-active.sync="
            isEditDiagnosticImageCategorySidebarActive
          "
          :itemEdit="itemEdit"
          :optionsCategories="optionsCategories"
          @editDiagnosticImageCategorySuccess="
            editDiagnosticImageCategorySuccess
          "
        />
        <DiagnosticImageCategoryAdd
          v-if="isAddNewDiagnosticImageCategorySidebarActive"
          :is-add-new-diagnostic-image-category-sidebar-active.sync="
            isAddNewDiagnosticImageCategorySidebarActive
          "
          :optionsCategories="optionsCategories"
          @createDiagnosticImageCategory="createDiagnosticImageCategory"
        />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25"
            />
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                v-if="$can('store', 'diagnostic-image-categorys')"
                variant="primary"
                @click="isAddNewDiagnosticImageCategorySidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Diagnostic Image Category</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(diagnostic_imagings)="data">
          <ul class="list-unstyled my-2 facility">
            <li v-for="(item, i) in data.item.diagnostic_imagings" :key="i">
              <b-badge variant="primary"
                ><span class="text-white" @click="llamado(item)">{{ item.name }}</span></b-badge
              >
            </li>
          </ul>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="$can('update', 'diagnostic-image-categorys')"
            variant="outline-primary"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Edit'"
            @click="editDiagnosticImageCategory(data.item)"
          >
            <i class="fas fa-pen icon-nm"></i>
          </b-button>

          <b-button
            v-if="$can('destroy', 'diagnostic-image-categorys')"
            variant="outline-danger"
            class="btn-icon btn-circle border-0"
            v-b-tooltip.hover.top="'Delete'"
            @click="deleteDiagnosticImageCategory(data.item)"
          >
            <i class="fas fa-trash-alt icon-nm"></i>
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import axiosAC from "@/core/services/api/admin/consults/diagnosticImagesCategories";
import axiosDI from "@/core/services/api/admin/consults/diagnosticImages";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import Swal from "sweetalert2";

import DiagnosticImageEdit from "../diagnostic-image/DiagnosticImageEdit.vue";
import DiagnosticImageCategoryEdit from "./DiagnosticImageCategoryEdit.vue";
import DiagnosticImageCategoryAdd from "./DiagnosticImageCategoryAdd.vue";
import DiagnosticImageCategoryFilter from "./DiagnosticImageCategoryFilter.vue";
import { ref } from "@vue/composition-api";
export default {
  components: {
    DiagnosticImageEdit,
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    DiagnosticImageCategoryEdit,
    DiagnosticImageCategoryAdd,
    DiagnosticImageCategoryFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        "id",
        { key: "name", label: "Name", sortable: true },
        {
          key: "diagnostic_imagings",
          label: "Diagnostic Imagings",
          sortable: true,
        },
        { key: "status", label: "Status" },
        // A virtual column made up from two fields
        { key: "actions", label: "Actions" },
      ],
      items: null,
      loading: false,
      isEditDiagnosticImageCategorySidebarActive: false,
      isAddNewDiagnosticImageCategorySidebarActive: false,
      isEditDiagnosticImageSidebarActive: false,
      itemEdit: null,
      optionsCategories: [],
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map((i) => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      diagnosticImageCategoryFilter: null,
    };
  },
  mounted() {
    this.getDiagnosticImageCategory();
    axiosDI.diagnosticImageList(20).then(({ data }) => {
      this.optionsCategories = data;
    });
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getDiagnosticImageCategory();
      } else {
        this.searchFilter(this.diagnosticImageCategoryFilter);
      }
    },
  },
  methods: {
    llamado(item){
        if (this.$can('update', 'diagnostic-images')) {
            this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
            this.isEditDiagnosticImageSidebarActive = true
        }
    },
    editDiagnosticImageSuccess(value) {
      if (value) {
        this.$refs.toast.success("Type DiagnosticImage updated successfully");
      } else {
        this.$refs.toast.error("Error updating type diagnostic-image");
      }
      this.isEditDiagnosticImageSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    getDiagnosticImageCategory() {
      axiosAC
        .diagnosticImageCategoryList(this.perPage)
        .then(({ data, current_page, total, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    editDiagnosticImageCategory(item) {
      this.isEditDiagnosticImageCategorySidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editDiagnosticImageCategorySuccess(value) {
      if (value) {
        this.$refs.toast.success(
          "Type DiagnosticImageCategory updated successfully"
        );
      } else {
        this.$refs.toast.error("Error updating type diagnostic-image-category");
      }
      this.isEditDiagnosticImageCategorySidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    createDiagnosticImageCategory({ status }) {
      if (status) {
        this.$refs.toast.success(
          "DiagnosticImageCategory type added successfully"
        );
      } else {
        this.$refs.toast.danger("DiagnosticImageCategory type not added");
      }
      this.isAddNewDiagnosticImageCategorySidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    deleteDiagnosticImageCategory(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosAC.diagnosticImageCategoryDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "DiagnosticImageCategory type has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosAC
          .diagnosticImageCategoryPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosAC
          .diagnosticImageCategoryFilterPagination(
            this.perPage,
            page,
            this.diagnosticImageCategoryFilter
          )
          .then(({registro: { data, total, current_page, ...res }}) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.diagnosticImageCategoryFilter = value;
        axiosAC
          .diagnosticImageCategoryFilter(this.perPage, value)
          .then(({registro: { data, total, current_page, ...res }}) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getDiagnosticImageCategory();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style></style>
